<template>
  <div class="privacystatement">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">Privacy Statement</div>
      <h1 class="border_text_title">隐私声明</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">隐私声明</span>
    </div>
    <div class="text_container text_style container_width">
        <div class="text_box">
            <h2>隐私声明</h2>
            <p>武汉贤牛科技有限公司（以下简称 “我们” ）是一个全球性媒体集团。无论是通过印刷、邮寄、电子邮件还是在网站上，我们都可以帮助客户改善广告和市场营销方式。我们相信，对数据进行负责任的使用，能够推动业务增长，在品牌和消费者之间建立强劲关系。作为一家企业，我们致力于尊重并保护我们与之互动的所有人的隐私。根据适用的隐私和数据保护法律，在处理个人信息的任何时间都始终遵守适用的隐私和数据保护法律，保持透明。 </p>
            <p>本隐私声明详细解释了当您与我们互动时我们可能收集的有关您的个人数据类型。电通国际是您提供给我们的任何个人数据（包括与本网站有关的数据）的数据控制者。</p>
            <p> 本隐私声明由电通全球法律团队制定，如果您所在国家/地区的适用法律/法规与本声明有任何冲突，则以较严格者为准。</p>
            <ul>
                <h2>本隐私声明解释了如下内容：</h2>
                <li>我们可能收集的信息</li>
                <li>我们如何使用此类信息</li>
                <li>我们如何存储您的个人数据</li>
                <li>我们如何保证您的个人数据安全</li>
                <li>信息共享和披露</li>
                <li>国际和集团公司转移</li>
                <li>您的权利</li>
                <li>我们对网站链接的责任</li>
                <li>更新</li>
                <li>如何联系我们</li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
export default {
  name: "privacystatement",

}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.privacystatement {
    .contactus_banner {
        background: url('../assets/imgs/privacystatement/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
    }
}
@media screen and (max-width: 1160px){}
@media screen and (max-width: 820px){}
@media screen and (max-width: 768px){}
</style>